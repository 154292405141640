import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Default/Layout'
import './ServiceDetailPage.css'
import Image from '../components/Default/Image'
import { Link } from '@reach/router'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb/components'
import Fade from 'react-reveal/Fade'
const ServiceDetailPageTemplate = props => {
  return (
    <div>
      <Breadcrumb crumbs={props.crumbs} crumbLabel={props.title} />
      <div className="ServiceDetailPage">
        <Image
          className="top-yellow"
          src={props.rightArrow.fluid.src}
          alt="top-yellow"
        />
        <Image
          className="top-blue"
          src={props.leftArrow.fluid.src}
          alt="top-blue"
        />
        <div className="container">
          <h1>{props.title}</h1>
          {props.image && (
            <div className="ServiceDetailPage-ImageWrap">
              <Image
                src={props.image.childImageSharp.fluid.src}
                alt="company-logo"
              />
            </div>
          )}
          <Fade bottom>
            <div dangerouslySetInnerHTML={{ __html: props.body }} />
          </Fade>
        </div>
        <div className="Contanct-area">
          <div className="container">
            <h3>Kontaktujte nás</h3>
            <a className="phone" href="tel:+420273139330">
              + 420 273 139 330
            </a>
            <div className="row align-items-start">
              <div className="col-lg-6 col-md-6 left">
                <Link to="/kontakt#formAnchor" className="button">
                  Kontaktní formulář
                </Link>
              </div>
              <div className="col-lg-6 col-md-6 right">
                <Link to="/kontakt#nasePobocky" className="button">
                  Naše pobočky
                </Link>
              </div>
            </div>
          </div>
          <Image
            className="bottom-yellow"
            src={props.rightArrow.fluid.src}
            alt="bottom-yellow"
          />
          <Image
            className="bottom-blue"
            src={props.leftArrow.fluid.src}
            alt="bottom-blue"
          />
        </div>
      </div>
    </div>
  )
}

const ServiceDetailPage = ({
  data: { page, rightArrow, leftArrow },
  pageContext
}) => {
  const {
    breadcrumb: { crumbs }
  } = pageContext
  return (
    <Layout
      meta={page.frontmatter.meta || false}
      title={page.frontmatter.title || false}
      metaCrumb={crumbs}
    >
      <ServiceDetailPageTemplate
        {...page.frontmatter}
        body={page.html}
        rightArrow={rightArrow.childImageSharp}
        leftArrow={leftArrow.childImageSharp}
        crumbs={crumbs}
      />
    </Layout>
  )
}

export default ServiceDetailPage

export const pageQuery = graphql`
  query ServiceDetailPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        subtitle
        image {
          childImageSharp {
            fluid(maxHeight: 200) {
              src
            }
          }
        }
      }
    }
    rightArrow: file(relativePath: { eq: "right-arrow.png" }) {
      childImageSharp {
        fluid {
          src
        }
      }
    }
    leftArrow: file(relativePath: { eq: "left-arrow.png" }) {
      childImageSharp {
        fluid {
          src
        }
      }
    }
  }
`
